@import url(./vars.less);

.App,
#root {
  // height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl {
  float: left;
  z-index: 8;
}

.fr {
  float: right;
  z-index: 8;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.global-spin {
  position: fixed;
  top: 50%;
  left: 50%;
}

/* 滚动条 */

/*滚动条宽度*/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle样式 */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/*当前窗口未激活的情况下*/

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

/*hover到滚动条上*/

::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/*滚动条按下*/

::-webkit-scrollbar-thumb:vertical:active {
  background-color: rgba(0, 0, 0, 0.7);
}

.bf-modal {
  z-index: 999 !important;
}

input::-webkit-inner-spin-button {
  display: none !important;
}

.ant-menu-item.ant-menu-item-only-child .anticon {
  margin-right: 10px !important;
}

.ant-layout-sider {
  &::-webkit-scrollbar {
    // width: 0;
    opacity: 0;
  }
  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

/* 自动填充样式修改 */
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #fff inset !important;
  text-fill-color: #000;
  -webkit-text-fill-color: #000;
  border-radius: 0;
}

.ant-tabs-bar {
  user-select: none;
}

// .ant-switch-inner {
//   margin-right: 6px;
//   margin-left: 24px;
// }

.ant-layout-content,
.ant-layout {
  min-height: unset !important;
}

.ant-form-item .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}

.logo {
  position: relative;
  padding: 0 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  height: 64px;

  img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
  }

  h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    vertical-align: middle;
    animation: fade-in;
    animation-duration: 0.3s;
  }
}

#globalLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  font-size: 20px;
}

// .ant-menu-item > a {
//   color: rgba(0, 0, 0, 0.65);
// }
// .ant-menu-item > a:hover {
//   color: #1890ff;
// }

.page {
  background: @page-backgroud;
  height: calc(100vh - 64px - 10px - 40px);
  overflow: auto;
  position: relative;
  padding-top: 16px;
}

.detail-page {
  padding-top: 16px;
  background: @page-backgroud;
  height: calc(100vh - 64px - 10px - 40px);
  overflow: auto;
  position: relative;
  .detail-page_main {
    height: calc(100% - 50px);
    overflow: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .detail-page_footer {
    height: 50px;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding-top: 9px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btn-link {
  // color: @link-color;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.h100 {
  height: 100%;
}

.wd100 {
  width: 100%;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.pd-il-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pb22 {
  padding-bottom: 22px;
}

.pb15 {
  padding-bottom: 15px;
}

.pd0 {
  padding-bottom: 0;
}

.pd10 {
  padding: 10px;
}

.fc-ccc {
  color: #ccc;
}

.containobjectfit {
  object-fit: contain;
}

.modalWrap {
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  // padding: 10px;
}
